import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";
import Truncate from "@/components/UI/Truncate/Truncate";

// See: https://tailwindui.com/components/application-ui/elements/badges
// See: https://mui.com/material-ui/react-chip/

const BadgeColor = {
  error500: "error500",
  neutral500: "neutral500",
  primary500: "primary500",
  success500: "success500",
  warning500: "warning500",
} as const;

const BadgeSize = {
  small: "small",
  medium: "medium",
  large: "large",
} as const;

const BadgeVariant = {
  outlined: "outlined",
  solid: "solid",
} as const;

const BadgeSizeConfig = {
  [BadgeSize.small]: "px-1 py-0.5 text-xs",
  [BadgeSize.medium]: "px-1.5 py-1 text-sm",
  [BadgeSize.large]: "px-2 py-1.5 text-md",
} as const;

const BadgeColorConfig = {
  [BadgeVariant.outlined]: {
    [BadgeColor.error500]: {
      base: "border border-vpRed-500 text-red-500",
      hover: {
        badge: "cursor-pointer hover:bg-vpRed-300",
        closeButton: "hover:text-vpRed-700",
      },
    },
    [BadgeColor.neutral500]: {
      base: "border border-vpGray-500 text-vpGray-900",
      hover: {
        badge: "cursor-pointer hover:border-vpGray-300",
        closeButton: "hover:text-vpGray-700",
      },
    },
    [BadgeColor.primary500]: {
      base: "border border-vpBlue-500 text-vpBlue-500",
      hover: {
        badge: "cursor-pointer hover:border-vpBlue-300",
        closeButton: "hover:text-vpBlue-700",
      },
    },
    [BadgeColor.success500]: {
      base: "border border-vpTeal-500 text-vpTeal-500",
      hover: {
        badge: "cursor-pointer hover:border-vpTeal-300",
        closeButton: "hover:text-vpTeal-700",
      },
    },
    [BadgeColor.warning500]: {
      base: "border border-vpOrange-500 text-vpOrange-500",
      hover: {
        badge: "cursor-pointer hover:bg-vpOrange-300",
        closeButton: "hover:text-vpOrange-700",
      },
    },
  },
  [BadgeVariant.solid]: {
    [BadgeColor.error500]: {
      base: "border border-vpRed-200 text-red-500 bg-vpRed-200",
      hover: {
        badge: "cursor-pointer hover:bg-vpRed-100",
        closeButton: "hover:text-vpRed-700",
      },
    },
    [BadgeColor.neutral500]: {
      base: "border border-vpGray-300 text-vpGray-900 bg-vpGray-300",
      hover: {
        badge: "cursor-pointer hover:bg-vpGray-100",
        closeButton: "hover:text-vpGray-700",
      },
    },
    [BadgeColor.primary500]: {
      base: "border border-vpBlue-200 text-vpBlue-500 bg-vpBlue-200",
      hover: {
        badge: "cursor-pointer hover:bg-vpBlue-100",
        closeButton: "hover:text-vpBlue-700",
      },
    },
    [BadgeColor.success500]: {
      base: "border border-vpTeal-300 text-vpTeal-500 bg-vpTeal-300",
      hover: {
        badge: "cursor-pointer hover:bg-vpTeal-100",
        closeButton: "hover:text-vpTeal-500",
      },
    },
    [BadgeColor.warning500]: {
      base: "border border-vpOrange-300 text-vpOrange-700 bg-vpOrange-300",
      hover: {
        badge: "cursor-pointer hover:bg-vpOrange-100",
        closeButton: "hover:text-vpOrange-500",
      },
    },
  },
} as const;

type RootNode = HTMLSpanElement;

interface BadgeProps extends React.HTMLAttributes<RootNode> {
  onClose?: React.HTMLAttributes<HTMLSpanElement>["onClick"];
  color?: keyof typeof BadgeColor;
  css?: {
    root?: string;
    closeButton?: string;
    children?: string;
  };
  rounded?: boolean;
  size?: keyof typeof BadgeSize;
  startNode?: React.ReactNode;
  variant?: keyof typeof BadgeVariant;
}

const Badge = forwardRef<RootNode, BadgeProps>(
  (
    {
      children,
      className,
      color = BadgeColor.primary500,
      css,
      onClose,
      onClick,
      rounded,
      size = BadgeSize.medium,
      startNode,
      variant = BadgeVariant.outlined,
      ...props
    },
    ref
  ) => {
    return (
      <span
        className={twMerge(
          classNames(
            "inline-flex items-center gap-1 whitespace-nowrap",
            BadgeColorConfig[variant][color].base,
            BadgeSizeConfig[size],
            rounded ? "rounded-full" : "rounded",
            {
              [BadgeColorConfig[variant][color].hover.badge]: onClick,
            },
            className,
            css?.root
          )
        )}
        onClick={onClick}
        ref={ref}
        {...props}
      >
        {Boolean(startNode) && <span>{startNode}</span>}
        {Boolean(children) && (
          <Truncate className={twMerge(classNames(css?.children))}>
            {children}
          </Truncate>
        )}
        {onClose && (
          <div
            aria-label="Close button"
            className={twMerge(
              classNames(
                "flex items-center justify-center",
                BadgeColorConfig[variant][color].hover.closeButton,
                css?.closeButton
              )
            )}
            onClick={onClose}
          >
            <FontAwesomeIcon className="cursor-pointer" icon={faXmark} />
          </div>
        )}
      </span>
    );
  }
);

Badge.displayName = Badge.name;

export default Badge;
