import classNames from "classnames";
import { useCallback, useState } from "react";
import { twMerge } from "tailwind-merge";
import Tooltip, { TooltipProps } from "@/components/UI/Tooltip";

interface TruncateProps extends TooltipProps {
  css?: {
    root?: string;
    content?: string;
  };
}

const Truncate = ({ className, children, css, ...props }: TruncateProps) => {
  const [contentEl, setContentEl] = useState<HTMLDivElement | null>(null);

  const setContentRef = useCallback((node: typeof contentEl) => {
    setContentEl(node);
  }, []);

  const isTruncated = contentEl
    ? contentEl.offsetWidth < contentEl.scrollWidth
    : false;

  return (
    <Tooltip
      className={twMerge(classNames(className, css?.root))}
      content={children}
      placement="top"
      showOnHover={isTruncated}
      size="extrasmall"
      {...props}
    >
      <div
        className={twMerge(classNames("truncate", css?.content))}
        ref={setContentRef}
      >
        {children}
      </div>
    </Tooltip>
  );
};

export default Truncate;
