import classNames from "classnames";
import NextLink, { LinkProps as NextLinkProps } from "next/link";
import { twMerge } from "tailwind-merge";
import LinkContent from "./LinkContent";

// See: https://mui.com/material-ui/react-link/

const LinkVariant = {
  external: "external",
  internal: "internal",
  static: "static",
} as const;

const LinkColor = {
  neutral900: "neutral900",
  primary300: "primary300",
  primary500: "primary500",
} as const;

const LinkColorConfig = {
  [LinkColor.neutral900]: "hover:text-vpGray-900 text-vpGray-700",
  [LinkColor.primary300]: "hover:text-vpBlue-300 text-vpBlue-300",
  [LinkColor.primary500]: "hover:text-vpBlue-700 text-vpBlue-500",
} as const;

export interface LinkProps extends React.HTMLAttributes<HTMLElement> {
  color?: keyof typeof LinkColor;
  href: NextLinkProps["href"];
  endNode?: React.ReactNode;
  replace?: NextLinkProps["replace"];
  startNode?: React.ReactNode;
  target?: HTMLAnchorElement["target"];
  variant?: keyof typeof LinkVariant;
}

// Note: Conditional types may be required for forwardRef given component varients.

const Link = ({
  className,
  children,
  color = LinkColor.primary500,
  endNode,
  href,
  startNode,
  variant = LinkVariant.internal,
  ...props
}: LinkProps) => {
  const rootClassName = twMerge(
    classNames(
      "align-center cursor-pointer flex transition",
      LinkColorConfig[color],
      className
    )
  );

  return variant === LinkVariant.external ? (
    <a className={rootClassName} href={href?.toString() ?? ""} {...props}>
      <LinkContent endNode={endNode} startNode={startNode}>
        {children}
      </LinkContent>
    </a>
  ) : variant === LinkVariant.internal ? (
    <NextLink className={rootClassName} href={href} {...props}>
      <LinkContent endNode={endNode} startNode={startNode}>
        {children}
      </LinkContent>
    </NextLink>
  ) : variant === LinkVariant.static ? (
    <div className={rootClassName} {...props}>
      <LinkContent endNode={endNode} startNode={startNode}>
        {children}
      </LinkContent>
    </div>
  ) : null;
};

export default Link;
