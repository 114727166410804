import { PropsWithChildren } from "react";

type LinkContentProps = PropsWithChildren<{
  endNode?: React.ReactNode;
  startNode?: React.ReactNode;
}>;

const LinkContent = ({ children, endNode, startNode }: LinkContentProps) => {
  return (
    <>
      {startNode && <span className="mr-1 flex items-center">{startNode}</span>}
      {children}
      {endNode && <span className="ml-1 flex items-center">{endNode}</span>}
    </>
  );
};

export default LinkContent;
