import { ForwardedRef, useEffect, useRef } from "react";

const useForwardedRef = <T,>(remoteRef: ForwardedRef<T>) => {
  const localRef = useRef<T>(null);

  useEffect(() => {
    if (!remoteRef) {
      return;
    } else if (typeof remoteRef === "function") {
      return remoteRef(localRef.current);
    } else {
      remoteRef.current = localRef.current;
    }
  });

  return localRef;
};

export default useForwardedRef;
