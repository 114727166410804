import Popover, { PopoverProps } from "./Popover";

export type TooltipProps = PopoverProps;

const Tooltip = ({
  color = "primary900",
  content,
  showOnHover = true,
  ...props
}: TooltipProps) => {
  return (
    <Popover
      color={color}
      content={content}
      showOnHover={Boolean(content) && showOnHover}
      {...props}
    />
  );
};

export default Tooltip;
