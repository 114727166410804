import { publicConfig } from "@/PublicConfig";
import { JobListResponseJob } from "@webmonitoring/vp-common";
import rand from "random-seed";

export interface PageMetadata {
  generateDate?: string;
  version?: string;
}

interface ToPublicPagesZipOptions {
  jobs?: JobListResponseJob[];
  pages?: any[];
}

export interface PublicPageZip {
  job: JobListResponseJob;
  page: any;
}

export const toPublicPagesZip = ({
  pages = [],
  jobs = [],
}: ToPublicPagesZipOptions) => {
  return pages?.reduce<PublicPageZip[]>((result, page) => {
    const job = jobs?.find((job) => {
      return job.id === Number(page?.attributes?.JobId);
    });

    return job ? [...result, { job, page }] : result;
  }, []);
};

interface GetRelatedPagesOptions {
  amount: number;
  locale: string;
  seed: string;
  items: PublicPageZip[];
}

export const getRelatedPages = (
  options: GetRelatedPagesOptions
): PublicPageZip[] => {
  const generator = rand.create(options.seed);

  const itemsByLocale = options.items.filter((item) => {
    return item.page?.attributes?.locale === options.locale;
  });

  const indexes = Array(options.amount)
    .fill(1)
    .map(() => {
      return generator(itemsByLocale.length);
    });

  return indexes.map((index) => {
    return itemsByLocale[index];
  });
};

interface GetPageLocaleCodesOptions {
  page: any;
}

export const getPageLocaleCodes = (options: GetPageLocaleCodesOptions) => {
  const currentPageLocale: string = options.page?.attributes?.locale;
  const otherPageLocales: string[] =
    options.page?.attributes?.localizations?.data?.map((item: any) => {
      return item?.attributes?.locale;
    }) ?? [];

  return [currentPageLocale, ...otherPageLocales];
};

interface GetPageLocalesOptions {
  locales: any;
  page: any;
}

export const getPageLocales = (options: GetPageLocalesOptions) => {
  const codes = getPageLocaleCodes({ page: options.page });

  return codes.map((code) => {
    return {
      code,
      name:
        options.locales?.find((item: any) => item?.code === code)?.name ?? "",
    };
  });
};

interface GetPageUrlOptions {
  page: any;
}

export const getPageUrl = (options: GetPageUrlOptions) => {
  const defaultLocale = "en";

  const url = new URL(publicConfig.visualpingWebURL);

  const pathname =
    options.page?.attributes?.locale === defaultLocale
      ? `/pages/${options.page?.attributes?.Slug}`
      : `/${options.page?.attributes?.locale}/pages/${options.page?.attributes?.Slug}`;

  url.pathname = pathname;

  return url;
};
