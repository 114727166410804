import type { Placement } from "@popperjs/core";
import classNames from "classnames";
import { forwardRef, useCallback, useEffect, useState } from "react";
import { usePopper } from "react-popper";
import { twMerge } from "tailwind-merge";
import useForwardedRef from "../../hooks/useForwardedRef";

const PopoverColor = {
  error: "bg-vpRed-500 text-white",
  neutral: "bg-white",
  gray: "bg-vpGray-500",
  gray200: "bg-vpGray-200",
  primary: "bg-vpBlue-500 text-white",
  primary150: "bg-vpBlue-150 text-vpBlue-700",
  primary900: "bg-vpBlue-900 text-white",
} as const;

const PopoverPosition = {
  absolute: "absolute",
  fixed: "fixed",
} as const;

const PopoverSize = {
  extrasmall: "leading-5 max-w-xs py-1 px-2 shadow-sm text-xs",
  small: "leading-5 max-w-lg py-2 px-3 shadow-sm text-sm",
  medium: "leading-5 max-w-3xl py-3 px-4 shadow-sm text-md",
  large: "leading-5 py-4 px-5 shadow-sm",
} as const;

type RootNode = HTMLDivElement;

export interface PopoverProps extends React.HTMLAttributes<RootNode> {
  closeOnPopOverHover?: boolean;
  color?: keyof typeof PopoverColor;
  css?: {
    anchor?: string;
    popper?: {
      root?: string;
      content?: string;
    };
    root?: string;
  };
  disableOutsideClick?: boolean;
  disablePadding?: boolean;
  content?: React.ReactNode;
  closeOnClick?: boolean;
  noArrow?: boolean;
  noOffset?: boolean;
  position?: keyof typeof PopoverPosition;
  onClose?: () => void;
  placement?: Placement;
  preventInsideClickClose?: boolean;
  show?: boolean;
  showOnClick?: boolean;
  showOnHover?: boolean;
  size?: keyof typeof PopoverSize;
}

const offsetModifier: any = ({ placement }: { placement: Placement }) => {
  if (placement.includes("start")) return [-15, 0];
  else if (placement.includes("end")) return [15, 0];
  return [0, 0];
};

const Popover = forwardRef<RootNode, PopoverProps>(
  (
    {
      children,
      content,
      className,
      closeOnClick = false,
      closeOnPopOverHover = false,
      color = "neutral",
      css,
      disableOutsideClick = false,
      disablePadding,
      placement = "bottom",
      show = false,
      showOnHover,
      showOnClick,
      size = "small",
      noArrow = false,
      noOffset = false,
      onClose,
      position = "fixed",
      preventInsideClickClose = false,
      ...props
    },
    rootRefProp
  ) => {
    const rootRef = useForwardedRef(rootRefProp);

    const [popoverRef, setPopoverRef] = useState<HTMLDivElement | null>(null);
    const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);
    const [showPopper, setShowPopper] = useState(show);
    const [arrowStyle, setArrowStyle] = useState<
      { top?: string | number; bottom?: string | number } | undefined
    >(undefined);

    const { styles, attributes } = usePopper(rootRef?.current, popoverRef, {
      strategy: position,
      placement: placement,
      modifiers: [
        {
          name: "arrow",
          options: {
            element: arrowRef,
          },
        },
        {
          name: "offset",
          options: {
            offset: !noOffset && !noArrow ? offsetModifier : undefined,
          },
        },
      ],
    });

    const onShow = () => {
      setShowPopper(true);
    };

    const onHide = useCallback(() => {
      onClose?.();

      setShowPopper(false);
    }, [onClose]);

    const onToggle = () => {
      if (showPopper) {
        onClose?.();
      }

      setShowPopper(!showPopper);
    };

    useEffect(() => {
      const handleClickOutside = (e: any) => {
        if (rootRef?.current?.parentNode?.contains(e.target)) {
          return;
        }

        onHide();

        document.removeEventListener("mousedown", handleClickOutside);
      };

      if (showPopper && !disableOutsideClick) {
        document.addEventListener("mousedown", handleClickOutside);
      }

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [disableOutsideClick, onHide, showPopper, rootRef]);

    const popPos = attributes["popper"]
      ? attributes["popper"]["data-popper-placement"]
      : undefined;

    const isVertAxis = popPos
      ? popPos.includes("top") || popPos.includes("bottom")
      : placement.includes("top") || placement.includes("bottom");

    useEffect(() => {
      if (popPos) {
        let arrow = popPos.includes("top")
          ? { bottom: "9px" }
          : popPos.includes("bottom")
          ? { top: "9px" }
          : popPos.includes("right")
          ? { top: "0px", bottom: "", left: "9px" }
          : popPos.includes("left")
          ? { top: "0px", bottom: "", right: "9px" }
          : {};

        setArrowStyle({ ...arrow });
      }
    }, [popPos]);

    useEffect(() => {
      setShowPopper(show);
    }, [show]);

    return (
      <div
        className={twMerge(classNames("relative", className, css?.root))}
        onClick={
          (showOnClick && !preventInsideClickClose) || closeOnClick
            ? onToggle
            : undefined
        }
        onMouseEnter={showOnHover ? onShow : undefined}
        onMouseLeave={showOnHover ? onHide : undefined}
        ref={rootRef}
        {...props}
      >
        <div
          className={twMerge(classNames("flex justify-start", css?.anchor))}
          onClick={showOnClick ? onToggle : undefined}
        >
          {children}
        </div>
        {showPopper && (
          <div
            className={twMerge(
              classNames(
                "z-50",
                noArrow
                  ? isVertAxis
                    ? "py-1"
                    : "px-1"
                  : isVertAxis
                  ? "py-3"
                  : "px-3",
                css?.popper?.root
              )
            )}
            onMouseEnter={() => {
              if (closeOnPopOverHover) {
                setShowPopper(false);
              }
            }}
            ref={setPopoverRef}
            style={styles["popper"]}
            {...attributes["popper"]}
          >
            <div
              className={twMerge(
                classNames(
                  "relative z-10 rounded-md",
                  PopoverSize[size],
                  PopoverColor[color],
                  {
                    "p-0": disablePadding,
                  },
                  css?.popper?.content
                )
              )}
            >
              {content}
            </div>
            <div
              ref={setArrowRef}
              style={{
                ...styles["arrow"],
                width: "12px",
                height: "12px",
                ...arrowStyle,
              }}
            >
              {!noArrow && (
                <div
                  className={twMerge(
                    classNames(
                      "h-full w-full rotate-45 transform rounded-sm",
                      PopoverColor[color],
                      arrowStyle ? "opacity-100" : "opacity-0"
                    )
                  )}
                />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
);

Popover.displayName = Popover.name;

export default Popover;
